import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "templateKey": "en-about",
  "seo": {
    "title": "About",
    "descr": "-"
  },
  "hero": {
    "title": "Company",
    "text": "test",
    "bg": "/img/bg-about.jpg",
    "heroTextList": [{
      "text": "Zimply is an innovation company that develops Digital Assistants for automating processes. Our Digital Assistants are based on technologies within Intelligent Automation. Zimply combines excellence and experience within Intelligent Automation and AI to find innovative solutions for our clients."
    }, {
      "text": "Our solutions within automation optimises and makes organisations ready for the future. We provide services such as AI-strategies to Digital Assistants that save time as well as costs and liberates employees from repetitive, time-consuming and mundane work tasks. With our clients' needs in focus we create solutions that contribute to a great business case. Zimply’s cross-functional team and product independence mean that we can provide tailored solutions that suit your organisation."
    }]
  },
  "content": [{
    "type": "simple",
    "simple": [{
      "bgColor": "#f8f8f8",
      "title": "Our team",
      "buttonText": "Our team",
      "buttonPath": "team",
      "textSection": [{
        "text": "Our team at Zimply are problem solvers with a common interest - to create great value with groundbreaking technology. We all have a long experience from Intelligent Automation and technologies such as Machine Learning, RPA (Robotic Process Automation), Computer Vision and Artificial Intelligence. Together we have delivered solutions within automation for our clients in various business functions and sectors. For us it is important to always be curious and passionate, with a focus on providing quality and business value.",
        "boldText": "-"
      }, {
        "boldText": "-",
        "text": "Our developers are certified in all of the tools that we use. In RPA we are certified in UiPath, Blue Prism, Pega Robotics and Kofax Kapow."
      }],
      "iconOverTitle": "/img/null.png"
    }]
  }, {
    "type": "full width image",
    "image": [{
      "image": "/img/office2.webp",
      "title": "-",
      "text": "-",
      "height": 0,
      "overlay": false
    }]
  }, {
    "type": "points",
    "simple": [],
    "typePoints": [{
      "title": "Our values",
      "points": [{
        "icon": "/img/hand.png",
        "text": "We have a high level of competence within many areas and sectors which can be seen in the solutions we have delivered. Our combined experiences and knowledge affects everything we create which contributes to high quality solutions.",
        "title": "Quality"
      }, {
        "text": "Vår bransch förändras ständigt och höga krav ställs på oss. Därför är vi kreativa och flexibla för att kunna möta våra kunders behov. Vi ligger i framkant och är nyfikna på hur marknaden kommer att utvecklas. The automation space is continuously changing and demands are high. Therefore we are creative and flexible in order to meet our clients needs and expectations. We are at the forefront and always curious on how the market will evolve over time. Zimply is doing what we can to democratize automations of repetitive processes and we believe that everyone has a right to a Digital Assistant.",
        "icon": "/img/lightbulb.png",
        "title": "Creativity"
      }, {
        "icon": "/img/heart.png",
        "text": "At Zimply we are a close team that enjoys the work that we are doing. We are helpful, inclusive and committed. We are driven by learning new things, not afraid of challenges in order to grow together as a team.",
        "title": "Passion"
      }]
    }]
  }, {
    "type": "full width image",
    "image": [{
      "image": "/img/bg-glasses.jpg",
      "title": "Vision",
      "text": "We are motivated by being at the forefront of technological development and to be a part of enabling stimulating work for our clients, to reach business goals and to improve the customer experience. At Zimply we believe in the positive possibilities that come from technological advancements and view our intelligent solutions as a catalizator for more sustainable organisations and happier employees.",
      "height": 2,
      "overlay": true,
      "textBlocks": [{
        "text": "We are motivated by being at the forefront of technological development and to be a part of enabling stimulating work for our clients, to reach business goals and to improve the customer experience. At Zimply we believe in the positive possibilities that come from technological advancements and view our intelligent solutions as a catalizator for more sustainable organisations and happier employees."
      }]
    }]
  }, {
    "type": "simple",
    "simple": [{
      "bgColor": "#ffffff",
      "title": "Digital Transformation",
      "buttonText": "-",
      "buttonPath": "-",
      "textSection": [{
        "text": "The digital world is changing rapidly and is driven by increased competition on a global scale. Because of this ever more companies are investing in technologies such as Intelligent Automation, RPA and Machine Learning. Our Digital Assistants enable automation of repetitive processes with high business value for organisations. Let our experts help you by automating parts of your business as the next step in the digital transformation.",
        "boldText": "-"
      }],
      "iconOverTitle": "/img/null.png"
    }]
  }, {
    "type": "simple",
    "simple": [{
      "title": "Strategy",
      "textSection": [{
        "boldText": "-",
        "text": "With our long experience and broad competence we give our clients a well performing, hands-on, long lasting and efficient strategy to automate manual processes that are mundane, time-consuming and repetitive. We create business cases and forecasts for your automation projects as well as KPI’s for an easy follow-up over time. If you already have a strategy in place we help you realize it. We work closely and together with you in order to evolve your strategy to meet your goals."
      }, {
        "boldText": "-",
        "text": "Zimply can help you create a sustainable way-of-working and put it into production as well as defining a scalable plan. We do it by integrating Intelligent automation in your organisation as well as building and running your internal Center-of-Excellence (COE)."
      }],
      "buttonText": "-",
      "buttonPath": "-",
      "bgColor": "#f6f6f6",
      "iconOverTitle": "/img/null.png"
    }]
  }, {
    "type": "full width image",
    "image": [{
      "image": "/img/bg-office4.jpg",
      "title": "-",
      "text": "-",
      "height": -1,
      "overlay": false
    }]
  }, {
    "type": "simple",
    "simple": [{
      "title": "Requirements Specification, Business Analysis and Development",
      "bgColor": "#ffffff",
      "textSection": [{
        "boldText": "-",
        "text": "We perform a process evaluation or workshop to identify processes that have best potential for a Digital Assistant and Intelligent Automation. We also write a Business Case that outlines the reasoning for going forward with a Digital Assistant and process automation based on various metrics."
      }, {
        "boldText": "-",
        "text": "Our skilled and certified Business Analysts and Developers work closely together with your organisation in order to capture and document requirements and develop process automations in an efficient way. This way we continuously deliver business value in very short time. Furthermore, our Digital Assistants and the process automations are tested thoroughly and documented before being implemented."
      }],
      "buttonText": "-",
      "buttonPath": "-",
      "iconOverTitle": "/img/null.png"
    }]
  }, {
    "type": "simple",
    "simple": [{
      "bgColor": "#f6f6f6",
      "title": "Maintenance & Support",
      "textSection": [{
        "boldText": "-",
        "text": "When you hire a Digital Assistant from Zimply, maintenance & support is included. This means that you can sit back and relax while we do the dirty work, always ensuring that your Digital Assistants work when they are supposed to. Let us run your Digital Assistants while you focus on your business and what you know best."
      }, {
        "boldText": "Quality",
        "text": "Our maintenance team Quality Assures all the process automations and Digital Assistants by reviewing and evaluating them before implementation to the production environment. We also have our own Digital Assistants that are used to review and verify process automations to ensure that they follow “Best Practice”."
      }, {
        "boldText": "Operations and updates",
        "text": "Zimply’s maintenance and support team ensures that the Digital Assistants are available and that they run according to the decided plan and schedule. We take sole responsibility and run the operations of the Digital Assistants by surveillance and continuously performing maintenance and updates."
      }, {
        "boldText": "Continuous improvements",
        "text": "We help our clients to secure the great potential of Intelligent Automation by quality assuring process automations and always improving your Digital Assistants. This leads to increased efficiency and productivity."
      }, {
        "boldText": "Pricing",
        "text": "We hire out the Digital Assistants on a monthly subscription, but are flexible in offering the pricing model that suits your organisation best. Contact us to learn more about the pricing."
      }, {
        "boldText": "Education",
        "text": "We can provide training for your organisation regarding Digital Assistants, Intelligent Automation and AI."
      }],
      "buttonText": "-",
      "buttonPath": "-",
      "iconOverTitle": "/img/null.png"
    }]
  }, {
    "type": "simple",
    "simple": [{
      "bgColor": "#ffffff",
      "title": "Robotics-as-a-Service",
      "buttonText": "-",
      "buttonPath": "-",
      "textSection": [{
        "boldText": "Easy implementation",
        "text": "Get started with automation in no time with Zimply’s end-to-end service created by our experienced Intelligent Automation team"
      }, {
        "boldText": "Cloud",
        "text": "We create and hire out Digital Assistants through a scalable cloud service."
      }, {
        "boldText": "Achieve cost reductions in short time",
        "text": "We have the infrastructure, systems, software and team in place, therefore we can get your tailored Digital Assistants up and running in fast. Easy, efficient and profitable."
      }, {
        "boldText": "Reduced risk",
        "text": "We offer operations of IT environments, software and maintenance & support in an all-in-one package with a monthly subscription. The uptime and scope of the service is defined in our SLA (Service Level Agreement)."
      }],
      "iconOverTitle": "/img/cloud.png"
    }]
  }, {
    "type": "full width image",
    "simple": [],
    "image": [{
      "image": "/img/mindmap_en.jpg",
      "title": "Advantages of automation",
      "text": "✓ Free up time to renew, develop and focus on customer satisfaction ✓ Reduce costs ✓ High ROI ✓ Increased Quality ✓ Reduced risk for human errors while increasing productivity ✓ Flexible and Scalable",
      "height": 0,
      "overlay": true,
      "checkTextBlocks": [{
        "text": "Free up time to renew, develop and focus on customer satisfaction"
      }, {
        "text": "Reduce costs"
      }, {
        "text": "High ROI"
      }, {
        "text": "Increased Quality"
      }, {
        "text": "Reduced risk for human errors while increasing productivity"
      }, {
        "text": "Flexible and Scalable"
      }]
    }]
  }],
  "pink": {
    "title": "Get started",
    "text": "Invest in the future and get a Digital Assistant for your organisation. Follow us into a world of possibilities, tailored to your business needs. Contact us today.",
    "buttonText": "Contact us",
    "buttonPath": "contact"
  },
  "endSection": {
    "buttonText": "Go to",
    "buttonPath": "digitalassistants",
    "icon": "/img/cost.png",
    "title": "Our assistants"
  }
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      